<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="XeroOverview"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 pl-5 pr-4>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-tabs
                        v-model="xeroIntegrationsCurrentTab"
                        color="success"
                        background-color="grey lighten-2"
                        slider-color="appic-green"
                    >
                        <v-tab key="pending">{{ $t('message.pending') }}</v-tab>
                        <v-tab key="integrated">{{ $t('message.integrated') }}</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="xeroIntegrationsCurrentTab">
                        <v-tab-item key="pending-list">
                            <XeroPendingIntegrationList></XeroPendingIntegrationList>
                        </v-tab-item>
                        <v-tab-item key="integrated-list">
                            <XeroIntegratedList></XeroIntegratedList>
                        </v-tab-item>
                    </v-tabs-items>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";

const XeroPendingIntegrationList = () => import("Components/Appic/XeroPendingIntegrationList");
const XeroIntegratedList = () => import("Components/Appic/XeroIntegratedList");

export default {
    name: "XeroOverview",
    components: {XeroIntegratedList, XeroPendingIntegrationList},
    title: '',
    data() {
        return {
            loader: false
        }
    },
    computed: {
        ...mapFields('runtime',{
            xeroIntegrationsCurrentTab: 'xeroIntegrationsCurrentTab'
        })
    },
    mounted(){
        this.$title = this.$t('message.titles.xero')
    }
}
</script>

<style scoped>

</style>